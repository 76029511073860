import React, { FC } from "react";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import SEO from "../components/Seo";
import useMarkdown from "../hooks/useMarkdown";
import styled from "styled-components";
import Image, { FluidObject } from "gatsby-image";
import useImages from "../hooks/useImages";

const Content = styled.div`
  padding: var(--s12);
  h2 {
    margin-bottom: var(--s4);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  @media screen and (max-width: 700px) {
    padding: var(--s4);
  }
  a,
  a:visited {
    text-decoration: underline;
    color: currentColor;
  }
  li,
  p {
    font-weight: 300;
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  > div > ul {
    max-width: 1500px;
    > li {
      display: grid;
      grid-template-columns: 1fr 3fr;
      border-top: 1px solid var(--grey);
      padding: var(--s2) 0;
    }
  }

  a:not([href="http://brunoheller.ch/0144"])
  {
    font-size: 1.6rem;
    line-height: 2.4rem;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
    text-decoration: underline;
  }
`;

type Buch = {
  fluid: FluidObject;
  title: string;
  subtitle: string;
  meta1: any;
  meta2: string;
  link: string;
};

const PublikationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--s12);

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  .meta,
  .description {
    font-size: 1.6rem;
    line-height: 2.4rem;
    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    a,
    a:visited,
    a:link {
      color: currentColor;
    }
  }
`;

const Publikation: FC<Buch> = ({ fluid, title, subtitle, meta1, meta2, link }) => (
  <PublikationContainer>
    <Image
      imgStyle={{
        objectFit: "contain",
        objectPosition: "0% 0%",
      }}
      fluid={fluid}
    />
    <div>
      <h3>{title}</h3>
      <p>{subtitle}</p>
      <p style={{ marginTop: "4rem" }} className="meta">
        {meta1}
      </p>
      {meta2 && meta2.length > 0 && (
        <p style={{ marginTop: "2rem" }} className="meta">
          {meta2}
        </p>
      )}
      {link && link.length > 0 && (
        <a style={{ marginTop: "2rem" }} className="meta" href={link}>
          Link zum Verlag
        </a>
      )}
    </div>
  </PublikationContainer>
);

const PublikationPage: FC = () => {
  const { html } = useMarkdown("publikationen")[0];
  const [clairobscurImage, transparentmontagenImage, maestroCollageImage] = useImages([
    "clairobscur",
    "transparentmontagen",
    "maestrocollage",
  ]);

  const buecher: Buch[] = [
    {
      fluid: (clairobscurImage.fluid as any) as FluidObject,
      title: "Clair-Obscur",
      subtitle: "Das Werk von Bruno Heller",
      meta1: (
        <>
          <p className="description">Mit Beiträgen von Johanna Wirth Calvo und Bernhard Echte</p>
          <br />
          <p className="description">2019</p>
          <p className="description">208 Seiten mit 145 Abbildungen</p>
          <p className="description"> 29.5 x 18.5 cm</p>
          <p className="description"> ISBN 978-3-03850-063-6</p>
        </>
      ),
      meta2: "NIMBUS, Kunst und Bücher",
      link: "http://www.nimbusbooks.ch/buch/clair-obscur",
    },
    {
      fluid: (transparentmontagenImage.fluid as any) as FluidObject,
      title: "Bruno Heller",
      subtitle: "Transparentmontagen",
      meta1: (
        <>
          <p className="description">Mit Beiträgen von Bernhard Echte und Ruedi Angele</p>
          <br />
          <p className="description">2008</p>
          <p className="description">144 Seiten mit 70 Abbildungen</p>
          <p className="description"> 29.5 x 18.5 cm</p>
          <p className="description"> ISBN 978-3-907142-36-3</p>
        </>
      ),
      meta2: "NIMBUS, Kunst und Bücher",
      link: "http://www.nimbusbooks.ch/buch/transparentmontagen",
    },
    {
      fluid: (maestroCollageImage.fluid as any) as FluidObject,
      title: "Bruno Heller",
      subtitle: "El maestro del collage. \n Der Meister der Collage.",
      meta1: (
        <>
          <p className="description">Mit Beiträgen von Ana Navarrete Tudela, Bernhard Echte, César Sànchez Méndez</p>
          <br />
          <p className="description">2025</p>
          <p className="description">62 Seiten mit 25 Abbildungen</p>
          <p className="description"> 28.5 x 21.5cm</p>
          <p className="description"> ISBN 978-84-16463-72-5</p>
        </>
      ),
      meta2: "",
      link: "",
    },
  ];

  const BuchContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 50rem));
    grid-column-gap: var(--s32);
    grid-row-gap: var(--s16);
  `;

  return (
    <Layout activePage={Routes.PUBLIKATIONEN}>
      <SEO title="Publikationen" />
      <Content>
        <BuchContainer>
          {buecher.map(b => (
            <Publikation key={b.title} {...b} />
          ))}
        </BuchContainer>
        <div style={{ marginTop: "4rem" }} dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    </Layout>
  );
};

export default PublikationPage;
